import React from 'react';
import lock from '../../images/lock.svg';
import './SecurityPrivacySplash.scss';

export default function SecurityPrivacySplash({}) {
  return (
    <section className="security-privacy-splash">
      <div className="full-cover-card-dark">
        <img className="security-privacy-lock" src={lock} style={{ height: 50 }} alt="Lock icon"></img>
        <h2 className="security-privacy-title">Bank level security.<br />For your eyes only.</h2>
        <p className="security-privacy-content">
          Encrypted with 256-bit AES at rest and TLS in transit. <br />Your data isn't shared or sold anywhere.
        </p>
      </div>
    </section>
  );
}
