import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import PageTitles from '../components/pagetitles';

import Hero from '../components/Hero';
import Showcase from '../components/Showcase';
import SecurityPrivacySplash from '../components/SecurityPrivacySplash';

const IndexPage = () => (
  <Layout pageTitle={PageTitles.Home}>
    <SEO title={PageTitles.Home} />
    <Hero />
    <SecurityPrivacySplash />
    <Showcase />
  </Layout>
);

export default IndexPage;
