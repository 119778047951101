import React from 'react';
import './Feature.scss';

class AppStoreDownload extends React.Component {
  constructor(props) {
    super(props);
  }

  getImgComponent = () => <img src={this.props.image}></img>;
  getContentComponents = () => (
    <div>
      <h2>{this.props.title}</h2>
      {this.props.description.map(paragraph => {
        return <p>{paragraph}</p>;
      })}
    </div>
  );
  getFeatureComponent = (mirrored) => {
    if (mirrored) {
      return (
        <>
          {this.getContentComponents()}
          {this.getImgComponent()}
        </>
      )
    }
    return (
      <>
        {this.getImgComponent()}
        {this.getContentComponents()}
      </>
    )
  }

  render() {
    return (
      <div
        ref={this.props.innerRef}
        className={`feature-showcase feature-showcase-${
          this.props.mirrored ? 'mirrored' : 'regular'
        }`}
      >
        {this.getFeatureComponent()}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <AppStoreDownload innerRef={ref} {...props} />
));
