import React, { useRef } from 'react';
import { useEffect } from 'react';
import { TweenMax, TimelineLite, Power3 } from 'gsap';
import './Hero.scss';
import AppStoreDownload from '../AppStoreDownload/AppStoreDownload';
import applogo from '../../images/applogo.svg';

export default function Hero({}) {
  let heroRef = useRef(null);
  let splashRef = useRef(null);
  let applogoRef = useRef(null);
  let titleRef = useRef(null);
  let formRef = useRef(null);

  let tl = new TimelineLite({ delay: 0.2 });

  useEffect(() => {
    TweenMax.to(heroRef, 1, { css: { visibility: 'visible', opacity: 1 } });

    // Splash image animation
    tl.from(splashRef, 1.2, { y: -1280, ease: Power3.easeOut }, 'start').from(
      splashRef,
      2.2,
      { scale: 1.4, ease: Power3.easeOut },
      0.2
    );

    // Content animation
    tl.staggerFrom(
      [applogoRef, titleRef, formRef],
      1.2,
      { x: -1280, ease: Power3.easeOut },
      0.15,
      'start'
    );
  }, [tl]);

  return (
    <section id="hero" ref={el => (heroRef = el)}>
      <div id="hero-content">
        <img
          className="applogo"
          ref={el => (applogoRef = el)}
          src={applogo}
          alt={'Mosaic app logo.'}
        ></img>
        <h1 id="hero-title" ref={el => (titleRef = el)}>
          Finally. A personal finance app you enjoy using.
        </h1>
        {/* <EmailForm ref={el => (formRef = el)} id="hero-cta" /> */}
        <AppStoreDownload ref={el => (formRef = el)} height={60} />
      </div>
      <video
        autoPlay={true}
        playsInline={true}
        loop={true}
        muted={true}
        id="hero-splash"
        ref={el => (splashRef = el)}
      >
        <source
          src="https://storage.googleapis.com/mosaicmoney-assets/preview.mp4"
          type="video/mp4"
        />
      </video>
      {/* <img
        alt={'Screenshot of Mosaic app'}
        ref={el => (splashRef = el)}
        id="hero-splash"
        src={splash}
      ></img> */}
    </section>
  );
}
