import React from 'react';
import Feature from '../Feature';
import autocat from '../../images/autocat.svg';
import notification from '../../images/notification.svg';
import search from '../../images/search.svg';
import sync from '../../images/sync.svg';
import nw from '../../images/nw.svg';

import { useRef, useEffect } from 'react';
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './Showcase.scss';

export default function Showcase({}) {
  let nwRef = useRef(null);
  let categorizationRef = useRef(null);
  let searchRef = useRef(null);
  let customTriggersRef = useRef(null);
  let syncRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.from(nwRef, {
      x: -300,
      opacity: 0,
      ease: Power3.easeOut,
      duration: 2,
      scrollTrigger: { trigger: nwRef, start: 'top 80%' },
    });

    gsap.from(categorizationRef, {
      x: +300,
      opacity: 0,
      ease: Power3.easeOut,
      duration: 2,
      scrollTrigger: { trigger: categorizationRef, start: 'top 80%' },
    });

    gsap.from(searchRef, {
      x: -300,
      ease: Power3.easeOut,
      opacity: 0,
      duration: 2,
      scrollTrigger: { trigger: searchRef, start: 'top 80%' },
    });

    gsap.from(customTriggersRef, {
      x: +300,
      ease: Power3.easeOut,
      opacity: 0,
      duration: 2,
      scrollTrigger: { trigger: customTriggersRef, start: 'top 80%' },
    });

    gsap.from(syncRef, {
      x: -300,
      ease: Power3.easeOut,
      opacity: 0,
      duration: 2,
      scrollTrigger: { trigger: syncRef, start: 'top 80%' },
    });
  }, []);

  return (
    <div>
      <section id="showcase">
        <Feature
          title="Net Worth"
          description={[
            'Connect all your accounts to see how your net worth and balances change over time.',
          ]}
          image={nw}
          mirrored={false}
          ref={el => (nwRef = el)}
        ></Feature>
        <Feature
          title="Categorization"
          description={[
            'All transactions are automatically categorized with super accuracy.',
            'Beautiful design to help you  understand how your money is moving.',
          ]}
          image={autocat}
          mirrored={true}
          ref={el => (categorizationRef = el)}
        ></Feature>
        <Feature
          title="Powerful Search"
          description={[
            'Needle in a haystack? No problem.',
            'Use our powerful search to dig through your transaction history.',
          ]}
          image={search}
          mirrored={false}
          ref={el => (searchRef = el)}
        ></Feature>
        <Feature
          title="Custom Notifications"
          description={[
            'Create custom notification triggers to be notifed only for events you care about.',
            'Notifications when you get your paycheck? Done. Investment balance updates? Easy. Get creative.',
          ]}
          image={notification}
          mirrored={true}
          ref={el => (customTriggersRef = el)}
        ></Feature>
        <Feature
          title="Bank Availability"
          description={[
            'Supports over 11,500 banks across the world. Likelihood your bank is supported? Very likely.',
          ]}
          image={sync}
          mirrored={false}
          ref={el => (syncRef = el)}
        ></Feature>
      </section>
    </div>
  );
}
